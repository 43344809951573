
import BAccordion from 'buetify/lib/components/accordion/BAccordion';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'accordion-example',
	components: {
		BAccordion
	}
});
